import App from './App.vue';
import router from './router';
import store from './store';
import {apiRequest} from '@/utils';
import {createApp} from 'vue';
import {createHead} from '@vueuse/head';

const app = createApp(App);
const head = createHead();

app.config.globalProperties.$api = apiRequest;

app.use(head);
app.use(store);
app.use(router);
app.mount('#app');