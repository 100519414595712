import {collection, doc, onSnapshot} from 'firebase/firestore';
import {createStore} from 'vuex';
import {db} from '@/firebase';

const STORAGE_KEYS = {
    isLoggedIn: 'vuex_isLoggedIn',
    token: 'vuex_token',
    user: 'vuex_user',
};

const saveStatePlugin = (store) => {
    store.subscribe((mutation, state) => {
        localStorage.setItem(STORAGE_KEYS.isLoggedIn, JSON.stringify(state.isLoggedIn));
        localStorage.setItem(STORAGE_KEYS.token, state.token || '');
        localStorage.setItem(STORAGE_KEYS.user, JSON.stringify(state.user || null));
    });
};

const loadStateFromStorage = () => {
    return {
        firestoreUser: [],
        isBuyAiMnemosModalVisible: false,
        isLoggedIn: JSON.parse(localStorage.getItem(STORAGE_KEYS.isLoggedIn)) || false,
        areNotificationsVisible: true,
        notifications: [],
        token: localStorage.getItem(STORAGE_KEYS.token) || null,
        user: JSON.parse(localStorage.getItem(STORAGE_KEYS.user)) || null,
    };
};

let unsubscribeUser = null;
let unsubscribeNotifications = null;

const store = createStore({
    actions: {
        listenToFirestoreData({commit}) {
            // If the listeners are already active, unsubscribe before setting new ones
            if (unsubscribeUser) unsubscribeUser();
            if (unsubscribeNotifications) unsubscribeNotifications();

            const userRef = doc(db, 'users', this.state.user.id);

            // Set up user data listener
            unsubscribeUser = onSnapshot(userRef, (userSnapshot) => {
                commit('SET_FIRESTORE_USER', { ...userSnapshot.data() });
            }, (error) => {
                console.error('Error listening to Firestore user data:', error);
            });

            const notificationsRef = collection(db, `users/${this.state.user.id}/notifications`);

            // Set up notifications listener
            unsubscribeNotifications = onSnapshot(notificationsRef, (querySnapshot) => {
                const notifications = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                commit('SET_NOTIFICATIONS', notifications);
            }, (error) => {
                console.error('Error listening to Firestore notifications:', error);
            });
        },
        logInStore({dispatch}, {token, user}) {
            dispatch('setLoggedIn', true);
            dispatch('setToken', token);
            dispatch('setUser', user);
            dispatch('listenToFirestoreData');
        },
        logOutStore({dispatch}) {
            dispatch('setLoggedIn', false);
            dispatch('setToken', null);
            dispatch('setUser', null);
            dispatch('stopListeningToFirestoreData');
        },
        setBuyAiMnemosModalVisible({commit}, status) {
            commit('SET_BUY_AI_MNEMOS_MODAL_VISIBLE', status);
        },
        setLoggedIn({commit}, status) {
            commit('SET_LOGGED_IN', status);
        },
        setNotificationsVisible({commit}, status) {
            commit('SET_NOTIFICATIONS_VISIBLE', status);
        },
        setToken({commit}, token) {
            commit('SET_TOKEN', token);
        },
        setUser({commit}, user) {
            commit('SET_USER', user);
        },
        stopListeningToFirestoreData() {
            // Unsubscribe from Firestore listeners when logging out
            if (unsubscribeUser) unsubscribeUser();
            if (unsubscribeNotifications) unsubscribeNotifications();
            unsubscribeUser = null;
            unsubscribeNotifications = null;
        },
    },
    mutations: {
        SET_BUY_AI_MNEMOS_MODAL_VISIBLE(state, status) {
            state.isBuyAiMnemosModalVisible = status;
        },
        SET_FIRESTORE_USER(state, data) {
            state.firestoreUser = data;
        },
        SET_LOGGED_IN(state, status) {
            state.isLoggedIn = status;
        },
        SET_NOTIFICATIONS(state, notifications) {
            state.notifications = notifications;
        },
        SET_NOTIFICATIONS_VISIBLE(state, status) {
            state.areNotificationsVisible = status;
        },
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_USER(state, user) {
            state.user = user;
        },
    },
    plugins: [saveStatePlugin],
    state: loadStateFromStorage(),
});

export default store;
