<script>
import IconBtn from './IconBtn.vue';
import ModalComponent from './ModalComponent.vue';
import {goToAiMnemoPurchaseCheckout} from '@/utils'

export default {
  components: {
    IconBtn,
    ModalComponent,
  },
  data() {
    return {
      aiMnemosInPack: 200,
      alert: null,
      packCount: 1,
      submitting: false,
    };
  },
  methods: {
    decPackCount() {
      if (this.packCount > 1) {
        this.packCount--;
      }
    },
    emitClose() {
      this.$emit('close');
    },
    async goToAiMnemoPurchaseCheckout(packCount) {
      this.submitting = true;
      try {
        await goToAiMnemoPurchaseCheckout(packCount);
        this.emitClose();
      } catch (error) {
        this.alert = {
          message: error.message,
          status: 'error',
        };
        this.submitting = false;
      }
    },
    incPackCount() {
      this.packCount++;
    },
  },
}
</script>

<style scoped>
.pack-selector {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  margin-bottom: 20px;
}

button {
  border-radius: 50%;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: bold;
  height: 36px;
  margin-bottom: 0;
  padding: 0;
  width: 36px;
}

span {
  font-size: 19px;
  font-weight: bold;
}

:deep(svg) {
  margin-right: 0 !important;
}

@media (max-width: 410px) {
  span {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  span {
    font-size: 17px;
  }
}

@media (max-width: 390px) {
  span {
    font-size: 16px;
  }
}

@media (max-width: 370px) {
  span {
    font-size: 15px;
  }
}
</style>

<template>
  <ModalComponent
      :alert="alert"
      :submitting="submitting"
      :title="'Buy AI mnemonics'"
      @close="emitClose"
      @closeAlert="() => this.alert = null"
      @submit="() => this.goToAiMnemoPurchaseCheckout(this.packCount)"
      submitBtnText="Go to checkout"
      submitting-btn-text="Going to checkout..."
  >
    <p>How many AI mnemonics would you like to buy?</p>

    <div class="pack-selector">
      <IconBtn @click="decPackCount" icon="fa:minus"/>
      <span>{{ packCount * aiMnemosInPack }} AI mnemonics = {{ packCount }}$</span>
      <IconBtn @click="incPackCount" icon="fa:plus"/>
    </div>
  </ModalComponent>
</template>