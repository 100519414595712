<script>
import ModalComponent from './ModalComponent.vue';

export default {
  components: {
    ModalComponent,
  },
  name: 'ConfirmModal',
  props: {
    text: String,
  },
};
</script>

<template>
  <ModalComponent
      @close="() => $emit('close')"
      @submit="() => {
        $emit('confirm');
        $emit('close');
      }"
      cancelBtnText="No"
      submitBtnText="Yes"
  >
    <p>{{ text }}</p>
  </ModalComponent>
</template>