<script>
import ConfirmModal from './ConfirmModal.vue';
import LangDropdown from './LangDropdown.vue';
import ModalComponent from './ModalComponent.vue';
import {getLangOptions} from '@/utils';
import {mapState} from 'vuex';

export default {
  components: {
    ConfirmModal,
    LangDropdown,
    ModalComponent,
  },
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      alert: null,
      isConfirmAiMnemoPurchaseModalVisible: false,
      langFromCodeModal: this.mnemo ? this.mnemo.lang_from_code : this.langFromCode,
      langFromWordTextModal: this.mnemo ? this.mnemo.lang_from_word.text : this.langFromWordText,
      langToCodeModal: this.mnemo ? this.mnemo.lang_to_code : this.langToCode,
      langOptions: getLangOptions(),
      langToWordTextModal: this.mnemo ? this.mnemo.lang_to_word.text : this.langToWordText,
      mnemoLangCodeModal: this.mnemoLangCode,
      mnemoText: this.mnemo ? this.mnemo.text : '',
      submitting: false,
    };
  },
  methods: {
    closeConfirmAiMnemoPurchaseModal() {
      this.isConfirmAiMnemoPurchaseModalVisible = false;
    },
    confirmAiMnemoPurchase() {
      this.isConfirmAiMnemoPurchaseModalVisible = true;
    },
    emitClose() {
      this.$emit('close');
    },
    emitSetInputsAndSearch() {
      this.$emit(
          'setInputsAndSearch',
          this.langFromWordTextModal,
          this.langFromCodeModal,
          this.langToCodeModal,
          this.mnemoLangCodeModal,
      );
    },
    emitSetMnemos(mnemos) {
      this.$emit('setMnemos', mnemos);
    },
    async generateMnemo() {
      if (
          !this.langFromWordTextModal
          || !this.langFromCodeModal
          || !this.langToCodeModal
          || !this.mnemoLangCodeModal
      ) {
        this.alert = {
          message: `Please fill in all fields before generating a mnemonic.`,
          status: 'error',
        };
        return;
      }

      if (this.user.ai_mnemo_count <= 0 && !this.user.admin) {
        this.confirmAiMnemoPurchase();
        return;
      }

      this.submitting = true;
      try {
        await this.$api.post('mnemos/generate', {
          lang_from_code: this.langFromCodeModal,
          lang_from_word_text: this.langFromWordTextModal,
          lang_to_code: this.langToCodeModal,
          mnemo_lang_code: this.mnemoLangCodeModal,
        });

        this.emitSetInputsAndSearch();
        this.resetForm();
        this.emitClose();
      } catch (error) {
        this.alert = {
          message: error.response?.data?.message || 'Failed to generate mnemo. Please try again.',
          status: 'error',
        };
      } finally {
        this.submitting = false;
      }
    },
    resetForm() {
      this.langFromCodeModal = this.langFromCode;
      this.langFromWordTextModal = this.langFromWordText;
      this.langToCodeModal = this.langToCode;
      this.langToWordTextModal = '';
      this.mnemoLangCodeModal = this.mnemoLangCode;
      this.mnemoText = '';
    },
    async saveMnemo() {
      if (
          !this.langFromWordTextModal
          || !this.langFromCodeModal
          || !this.langToCodeModal
          || !this.langToWordTextModal
          || !this.mnemoLangCodeModal
          || !this.mnemoText
      ) {
        this.alert = {
          message: `Please fill in all fields before {$this.mnemo ? 'saving' : 'adding'} a mnemonic.`,
          status: 'error',
        };
        return;
      }

      if (this.mnemoLangCodeModal !== this.langFromCodeModal && this.mnemoLangCodeModal !== this.langToCodeModal) {
        this.alert = {
          message: 'Mnemonic language must be the same as from or to language.',
          status: 'error',
        };
        return;
      }

      this.submitting = true;

      try {
        const data = {
          first_lang_code: this.langFromCodeModal ?? undefined,
          first_lang_word_text: this.langFromWordTextModal,
          mnemo_lang_code: this.mnemoLangCodeModal ?? undefined,
          public: this.mnemoIsPublic,
          second_lang_code: this.langToCodeModal ?? undefined,
          second_lang_word_text: this.langToWordTextModal,
          text: this.mnemoText,
        };

        if (this.mnemo) {
          const response = await this.$api.put(`mnemos/${this.mnemo.id}`, data);

          this.emitSetMnemos(
              this.mnemos.map((item) =>
                  item.id === this.mnemo.id ? {...item, ...response.data} : item
              )
          );
        } else {
          await this.$api.post(`mnemos`, data);

          this.emitSetInputsAndSearch();
          this.resetForm();
        }

        this.emitClose();
      } catch (error) {
        this.alert = {
          message: error.response?.data?.message || 'Failed to save mnemonic. Please try again.',
          status: 'error',
        };
      } finally {
        this.submitting = false;
      }
    },
    showBuyAiMnemosModal() {
      this.setBuyAiMnemosModalVisible(true);
    },
    submit() {
      if (this.action === 'generate') {
        this.generateMnemo();
      } else {
        this.saveMnemo();
      }
    },
  },
  name: 'AddEditGenerateMnemoModal',
  props: {
    action: {
      type: String,
      required: true,
    },
    langFromCode: {
      type: String,
      required: true,
    },
    langFromWordText: {
      type: String,
      required: true,
    },
    langToCode: {
      type: String,
      required: true,
    },
    mnemo: {
      type: Object,
      default: null,
    },
    mnemos: {
      type: Array,
      required: true,
    },
    mnemoIsPublic: {
      type: Boolean,
      default: false,
    },
    mnemoLangCode: {
      type: String,
      required: true,
    },
  },
  watch: {
    langFromCode(newVal) {
      this.langFromCodeModal = newVal;
    },
    langFromWordText(newVal) {
      this.langFromWordTextModal = newVal;
    },
    langToCode(newVal) {
      this.langToCodeModal = newVal;
    },
    mnemo(mnemo) {
      if (mnemo) {
        this.langFromCodeModal = mnemo.lang_from_code;
        this.langFromWordTextModal = mnemo.lang_from_word_text;
        this.langToCodeModal = mnemo.lang_to_code;
        this.langToWordTextModal = mnemo.lang_to_word_text;
        this.mnemoText = mnemo.text;
      } else {
        this.resetForm();
      }
    },
    mnemoLangCode(newVal) {
      this.mnemoLangCodeModal = newVal;
    },
  },
};
</script>

<template>
  <ModalComponent
      :alert="alert"
      :submitBtnBgColor="mnemoIsPublic ? '#228B22' : action === 'generate' ? '#7531ea' : '#315fea'"
      :submitBtnText="(action === 'add') ? 'Submit' : (action === 'edit' ? 'Save' : 'Generate')"
      :submitting="submitting"
      :submittingBtnText="(action === 'add') ? 'Submitting...' : (action === 'edit' ? 'Saving...' : 'Generating...')"
      :title="`
        ${action ? action.charAt(0).toUpperCase() : ''}${action ? action.slice(1) : ''}
        ${mnemoIsPublic ? ' public' : ' private'}
        mnemonic${action === 'generate' ? ' with AI' : ''}
      `"
      @close="emitClose"
      @closeAlert="() => alert = null"
      @submit="submit"
  >
    <div class="parallel-inputs-container">
      <div class="form-group">
        <label for="lang-from-word-input-modal">Word to translate</label>
        <input
            id="lang-from-word-input-modal"
            placeholder="Enter word to translate"
            v-model="langFromWordTextModal"
        />
      </div>
      <div class="form-group" v-if="action !== 'generate'">
        <label for="lang-to-word-input-modal">Translated word</label>
        <input
            id="lang-to-word-input-modal"
            placeholder="Enter translated word"
            v-model="langToWordTextModal"
        />
      </div>
    </div>
    <div class="parallel-inputs-container">
      <div class="form-group">
        <label for="lang-from-dropdown-modal">From</label>
        <LangDropdown
            :dataSet="langOptions"
            id="lang-from-dropdown-modal"
            placeholder="From"
            v-model="langFromCodeModal"
        />
      </div>
      <div class="form-group">
        <label for="lang-to-dropdown-modal">To</label>
        <LangDropdown
            :dataSet="langOptions"
            id="lang-to-dropdown-modal"
            placeholder="To"
            v-model="langToCodeModal"
        />
      </div>
    </div>
    <div class="form-group">
      <label for="mnemo-lang-input">Mnemonic language</label>
      <LangDropdown
          :dataSet="langOptions"
          id="mnemo-lang-input"
          placeholder="Mnemonic language"
          v-model="mnemoLangCodeModal"
      />
    </div>
    <div class="form-group" v-if="action !== 'generate'">
      <label for="mnemo-text-modal">Mnemonic</label>
      <textarea
          class="input"
          id="mnemo-text-modal"
          rows="3"
          v-model="mnemoText"
      ></textarea>
    </div>
  </ModalComponent>
  <ConfirmModal
      @close="closeConfirmAiMnemoPurchaseModal"
      @confirm="showBuyAiMnemosModal"
      text="You have no AI mnemonics left. Would you like to buy some?"
      v-if="isConfirmAiMnemoPurchaseModalVisible"
  />
</template>
