import ISO6391 from 'iso-639-1';
import axios from 'axios';
import store from './store';

export const apiRequest = axios.create({
    baseURL: 'https://api.mnemosaurus.com/',
});

apiRequest.interceptors.request.use(
    (config) => {
        const token = store.state.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiRequest.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 404) {
            return Promise.resolve({ data: [] });
        }
        return Promise.reject(error);
    }
);

export async function goToAiMnemoPurchaseCheckout(packCount) {
    try {
        const response = await apiRequest.post('ai_mnemo_purchases/go_to_stripe_checkout', { pack_count: packCount });
        window.open(response.data.checkout_session_url);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const getLangOptions = () => {
    return ISO6391.getAllNames()
        .map((name) => ({
            label: name,
            value: ISO6391.getCode(name),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
};