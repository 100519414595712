<script>
import InlineAlert from './InlineAlert.vue';

export default {
  components: {
    InlineAlert,
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitCloseAlert() {
      this.$emit('closeAlert');
    },
  },
  name: 'ModalComponent',
  props: {
    alert: {
      required: false,
      type: Object,
    },
    cancelBtnText: {
      default: 'Cancel',
      type: String,
    },
    showCancelBtn: {
      default: true,
      type: Boolean,
    },
    submitBtnBgColor: {
      required: false,
      type: String,
    },
    submitBtnText: {
      default: 'Submit',
      type: String,
    },
    submitting: {
      default: false,
      type: Boolean,
    },
    submittingBtnText: {
      default: 'Submitting...',
      type: String,
    },
    title: {
      required: false,
      type: String,
    }
  },
};
</script>

<template>
  <div class="modal">
    <div class="modal-content">
      <h3 v-if="title">{{ title }}</h3>

      <InlineAlert :message="alert.message" :status="alert.status" @close="emitCloseAlert" v-if="alert" />

      <slot></slot>
      <div class="modal-btns-container">
        <button
            :disabled="submitting"
            @click="emitClose"
            class="cancel-btn"
            v-if="showCancelBtn"
        >
          {{ cancelBtnText }}
        </button>
        <button
            :disabled="submitting"
            :style="{ backgroundColor: this.submitBtnBgColor ?? '#315fea' }"
            @click="() => $emit('submit')"
        >
          {{ submitting ? submittingBtnText : submitBtnText }}
        </button>
      </div>
    </div>
  </div>
</template>