<template>
  <AppHeader title="Mnemosaurus"/>
  <section id="bg">
    <router-view></router-view>
  </section>
  <FooterComponent/>
  <DotBg/>
  <BuyAiMnemosModal
      @close="() => setBuyAiMnemosModalVisible(false)"
      v-if="isBuyAiMnemosModalVisible"
  />
  <NotificationModal
      :notification="importantNotifications[0]"
      @close="() => setNotificationsVisible(false)"
      @open="() => setNotificationsVisible(true)"
      v-show="areNotificationsVisible && importantNotifications.length"
  />
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import BuyAiMnemosModal from './components/BuyAiMnemosModal.vue';
import DotBg from './components/DotBg.vue';
import FooterComponent from './components/FooterComponent.vue';
import NotificationModal from './components/NotificationModal.vue';
import {mapActions, mapState} from 'vuex';
import {useHead} from '@vueuse/head';
import {useRoute} from 'vue-router';
import {computed, watch} from 'vue';

export default {
  components: {
    AppHeader,
    BuyAiMnemosModal,
    DotBg,
    FooterComponent,
    NotificationModal,
  },
  computed: {
    ...mapState(['areNotificationsVisible', 'isBuyAiMnemosModalVisible', 'isLoggedIn', 'notifications']),
    importantNotifications() {
      return this.notifications.filter(notification => notification.important);
    },
  },
  methods: {
    ...mapActions(['listenToFirestoreData', 'setBuyAiMnemosModalVisible', 'setNotificationsVisible']),
  },
  mounted() {
    if (this.isLoggedIn) {
      this.listenToFirestoreData();
    }
  },
  name: 'App',
  setup() {
    const route = useRoute();
    const baseUrl = window.location.origin;
    const canonicalUrl = computed(() => `${baseUrl}${route.path}`);

    watch(route, () => {
      useHead({
        meta: [
          {content: route.meta.noindex ? 'nofollow, noindex' : 'follow, index', name: 'robots'},
        ],
        link: [
          {rel: 'canonical', href: canonicalUrl.value},
        ],
      });
    }, {immediate: true});
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

#bg {
  min-height: calc(100vh - 122px);
  padding-bottom: 30px;
  padding-top: 30px;
}

.bg-canvas {
  left: 0;
  opacity: 0.1;
  position: fixed;
  top: 0;
  z-index: -1;
}

.both-langs {
  color: #7531ea;
}

.cancel-btn {
  background-color: #777;
}

.content {
  background-color: #151515;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: auto;
  max-width: 400px;
  opacity: 0.9;
  padding: 35px 20px 20px 20px;
}

.form-group {
  margin-bottom: 20px;
}

.inline-alert {
  align-items: flex-start;
  border-radius: 4px;
  display: flex;
  font-size: 15px;
  gap: 10px;
  line-height: 1.5;
  margin-bottom: 15px;
  margin-top: -25px;
  padding: 10px 15px;
  width: 100%;
}

.inputs-container, .parallel-inputs-container {
  display: flex;
  gap: 10px;
}

.inputs-container > *, .parallel-inputs-container > * {
  flex-basis: 0;
  flex-grow: 1;
}

.inputs-container > .parallel-inputs-container {
  flex-basis: 10px;
  flex-grow: 2;
}

.middle-wide-content {
  max-width: 1000px;
}

.mnemo button {
  width: auto;
}

.mnemo-lang {
  color: #4b77ff;
}

.mnemo-translation-newline {
  display: none;
}

.mnemos-container {
  text-align: center;
}

.mnemos-container h3 {
  text-align: left;
}

.modal-btns-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
}

.modal .inline-alert {
  margin-top: -15px;
}

.modal-content {
  background-color: #2a2a2a;
  padding: 30px 20px 10px 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  color: white;
}

.modal p {
  margin-top: 0;
}

.other-lang {
  color: #e14141;
}

.vue-select {
  font-family: Arial, sans-serif;
}

.vue-select .control {
  background-color: #232323 !important;
}

.vue-select .search-input {
  color: white !important;
  font-size: 15px !important;
  padding: 12px 10px !important;
}

.vue-select .menu-option {
  background-color: #1e1e1e !important;
  color: white !important;
  font-size: 14px !important;
}

.vue-select .menu-option.focused {
  background-color: #333 !important;
}

.vue-select .menu-option.selected {
  background-color: #444 !important;
}

.vue-select .menu-option.selected:hover {
  background-color: #444 !important;
}

.vue-select .menu-option:hover {
  background-color: #333 !important;
}

.wide-content {
  max-width: 1200px;
}

::-ms-input-placeholder {
  color: #aaa;
}

::placeholder {
  color: #aaa;
  opacity: 1;
}

:root {
  --vs-input-placeholder-color: #aaa !important;
}

a {
  color: white;
}

body {
  background-color: #121212;
  color: #ffffff;
  font-family: "Space Grotesk", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
  padding-top: 58.39px;
}

button {
  background-color: #315fea;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 15px;
  transition: opacity 0.3s;
  width: 100%;
}

button:hover {
  opacity: .8;
}

button[disabled] {
  cursor: auto !important;
  opacity: .6;
}

em {
  display: block;
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 30px;
  margin-top: 0;
}

h3 {
  margin-bottom: 20px;
  margin-top: 0;
}

input, textarea {
  background-color: #232323;
  border-radius: 5px;
  border: 1px solid white;
  color: #fff;
  font-family: Arial, sans-serif;
  font-size: 15px;
  padding: 15px 10px;
  width: 100%;
}

label {
  display: block;
  font-size: 14px;
  margin-bottom: 7px;
}

p {
  margin-top: 0;
}

textarea {
  min-height: 100px;
  width: 100%;
}

@media (max-width: 1000px) {
  #bg {
    padding: 0;
  }

  .bg-canvas {
    opacity: 0.2;
  }

  .content {
    border-radius: 0;
    box-shadow: none;
    max-width: none;
    min-height: calc(100vh - 58px);
    opacity: 0.8;
    padding-top: 15px;
    width: 100%;
  }

  .inline-alert {
    margin-top: -5px;
  }

  .mnemo-translation-dash {
    display: none;
  }

  .mnemo-translation-newline {
    display: inline;
  }

  .parallel-inputs-container > * {
    flex-grow: 1;
  }

  body {
    background-color: #1e1e1e;
  }

  h2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 540px) {
  .modal-content {
    max-width: calc(100% - 40px);
  }
}
</style>
