<template>
  <div class="content">
    <h2>Registration</h2>

    <InlineAlert :message="alert.message" :status="alert.status" @close="() => alert = null" v-if="alert" />

    <div class="form-container">
      <div class="form-group">
        <label class="label" for="email">Email</label>
        <input
            id="email"
            type="email"
            v-model="email"
        />
      </div>

      <div class="form-group">
        <label class="label" for="username">Username</label>
        <input
            id="username"
            type="text"
            v-model="username"
        />
      </div>

      <div class="form-group">
        <label class="label" for="password">Password</label>
        <input
            id="password"
            type="password"
            v-model="password"
        />
      </div>

      <div class="form-group">
        <label class="label" for="passwordConfirmation">Confirm password</label>
        <input
            id="passwordConfirmation"
            type="password"
            v-model="passwordConfirmation"
        />
      </div>

      <div class="checkbox-container">
        <input
            type="checkbox"
            id="acceptTerms"
            v-model="acceptTerms"
            class="checkbox"
        />
        <label for="acceptTerms">
          I have read and accept the
          <a @click.prevent="openPrivacyPolicy" href="#">Privacy policy</a> and
          <a @click.prevent="openTermsOfService" href="#">Terms of service</a>.
        </label>
      </div>

      <IconBtn
          :disabled="registering"
          :icon="registered ? 'fa:check' : null"
          :text="registering ? 'Registering...' : registered ? 'Registered' : 'Register'"
          @click="handleRegister"
      />
    </div>
  </div>
</template>

<script>
import InlineAlert from './InlineAlert.vue';
import IconBtn from "@/components/IconBtn.vue";

export default {
  components: {
    IconBtn,
    InlineAlert,
  },
  data() {
    return {
      acceptTerms: false,
      alert: null,
      email: '',
      password: '',
      passwordConfirmation: '',
      registered: false,
      registering: false,
      username: '',
    };
  },
  methods: {
    async handleRegister() {
      if (!this.username || !this.email || !this.password || !this.passwordConfirmation) {
        this.alert = {
          message: 'Please fill in all fields.',
          status: 'error',
        };
        return;
      }

      if (this.password !== this.passwordConfirmation) {
        this.alert = {
          message: 'Passwords do not match.',
          status: 'error',
        };
        return;
      }

      if (!this.acceptTerms) {
        this.alert = {
          message: 'You must accept the privacy policy and terms of service to register.',
          status: 'error',
        };
        return;
      }

      this.registering = true;

      try {
        await this.$api.post('register', {
          username: this.username,
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        });

        this.alert = {
          message: 'Registration successful. You can now <a href="login">log in</a>. We have sent you a verification email. Please verify your email address.',
          status: 'success',
        };

        this.resetForm();
      } catch (error) {
        this.alert = {
          message: error.response?.data?.message || 'An error occurred.',
          status: 'error',
        };
      } finally {
        this.registering = false;
      }
    },
    resetForm() {
      this.email = '';
      this.username = '';
      this.password = '';
      this.passwordConfirmation = '';
      this.acceptTerms = false;
    },
    openPrivacyPolicy() {
      window.open('https://mnemosaurus.com/privacy_policy', '_blank');
    },
    openTermsOfService() {
      window.open('https://mnemosaurus.com/terms_of_service', '_blank');
    },
  },
  name: 'RegistrationScreen',
};
</script>

<style scoped>
.checkbox-container {
  align-items: flex-start;
  display: flex;
  margin-bottom: 15px;
}

.checkbox {
  margin-right: 10px;
  position: relative;
  top: 1px;
  width: auto;
}
</style>
