<template>
  <div class="mnemo">
    <div class="mnemo-labels">
      <span v-if="item.public" class="mnemo-label public">Public</span>
      <span v-else class="mnemo-label private">Private</span>
      <span v-if="item.in_review" class="mnemo-label in-review">In Review</span>
      <span v-if="item.inappropriate" class="mnemo-label inappropriate">Inappropriate</span>
      <span v-if="item.ai_generated" class="mnemo-label ai-generated">AI-generated</span>
      <span v-if="item.edited" class="mnemo-label edited">Edited</span>
    </div>
    <div class="mnemo-txt-container">
      <p class="mnemo-translation">
        {{ item.lang_from_word.article ?? '' }}
        <b :class="item.lang_from_code === item.lang_code ? 'mnemo-lang' : 'other-lang'">
          {{ item.lang_from_word.text }}
        </b>
        ({{ langOptions.find(lang => lang.value === item.lang_from_code).label }})
        <span class="mnemo-translation-dash">-</span> <br class="mnemo-translation-newline">
        {{ item.lang_to_word.article ?? '' }}
        <b :class="item.lang_from_code === item.lang_code ? 'other-lang' : 'mnemo-lang'">
          {{ item.lang_to_word.text }}
        </b>
        ({{ langOptions.find(lang => lang.value === item.lang_to_code).label }})
      </p>
      <p class="mnemo-txt" v-html="formattedText"/>
    </div>
    <div class="mnemo-btns">
      <IconBtn
          :disabled="isOwnMnemo"
          :icon-size="14"
          :marked="item.my_reaction_counts?.like > 0"
          :mobile-text="item.reaction_counts?.like || '0'"
          :text="`Like (${item.reaction_counts?.like || '0'})`"
          @click="emitReactMnemo('like')"
          bg-color="#228B22"
          icon="fa:thumbs-up"
          v-if="item.public"
      />
      <IconBtn
          :disabled="isOwnMnemo"
          :marked="item.my_reaction_counts?.laugh > 0"
          :mobile-text="item.reaction_counts?.laugh || '0'"
          :text="`Lol (${item.reaction_counts?.laugh || '0'})`"
          @click="emitReactMnemo('laugh')"
          bg-color="#315fea"
          icon="mdi:emoticon-lol"
          v-if="item.public"
      />
      <IconBtn
          :disabled="isOwnMnemo"
          :icon-size="14"
          :marked="item.my_reaction_counts?.dislike > 0"
          :mobile-text="item.reaction_counts?.dislike || '0'"
          :text="`Dislike (${item.reaction_counts?.dislike || '0'})`"
          @click="emitReactMnemo('dislike')"
          bg-color="#e14141"
          icon="fa:thumbs-down"
          v-if="item.public"
      />
      <IconBtn
          :icon-size="14"
          @click="emitEditMnemo"
          bg-color="#315fea"
          icon="fa:pencil"
          text="Edit"
          v-if="!item.public"
      />
      <IconBtn
          :icon-size="14"
          @click="emitStopReviewMnemo"
          bg-color="#315fea"
          icon="mdi:hand-back-left"
          text="Stop review"
          v-if="!item.public && item.in_review"
      />
      <IconBtn
          :icon-size="14"
          @click="emitPublishMnemo"
          bg-color="#228b22"
          icon="fa:send"
          text="Publish"
          v-if="!item.public && !item.in_review"
      />
      <IconBtn
          :icon-size="14"
          @click="emitDeleteMnemo"
          bg-color="#e14141"
          icon="fa:trash"
          text="Delete"
          v-if="!item.public"
      />
    </div>
    <button v-if="item.public" class="report-link" @click="emitReportMnemo">Report</button>
  </div>
</template>

<script>
import IconBtn from './IconBtn.vue';
import {mapState} from 'vuex';
import {getLangOptions} from '@/utils';

export default {
  components: {
    IconBtn,
  },
  computed: {
    ...mapState(['isLoggedIn', 'user']),
    formattedText() {
      return this.item.text
          .replace(/<\/?(?!m|mo|o|\/m|\/mo|\/o\b)[^>]*>/g, '')
          .replace(/<m>(.*?)<\/m>/g, '<span class="mnemo-lang">$1</span>')
          .replace(/<mo>(.*?)<\/mo>/g, '<span class="both-langs">$1</span>')
          .replace(/<o>(.*?)<\/o>/g, '<span class="other-lang">$1</span>');
    },
    isOwnMnemo() {
      return this.isLoggedIn && this.user.id === this.item.user_id;
    },
  },
  data() {
    return {
      langOptions: getLangOptions(),
    };
  },
  methods: {
    emitEditMnemo() {
      this.$emit('edit', this.item);
    },
    emitDeleteMnemo() {
      this.$emit('delete', this.item);
    },
    emitPublishMnemo() {
      this.$emit('publish', this.item);
    },
    emitReportMnemo() {
      this.$emit('report', this.item);
    },
    emitReactMnemo(type) {
      this.$emit('react', this.item, type);
    },
    emitStopReviewMnemo() {
      this.$emit('stop-review', this.item);
    },
  },
  name: 'MnemoItem',
  props: {
    item: {
      required: true,
      type: Object,
    },
    langFromWordText: {
      required: true,
      type: String,
    },
    langFromCode: {
      required: true,
      type: String,
    },
    langToCode: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.ai-generated {
  background-color: #7531ea;
}

.edited {
  background-color: #315fea;
}

.icon-btn {
  height: 40px;
}

.in-review {
  background-color: #d97706;
}

.inappropriate {
  background-color: #e14141;
}

.mnemo {
  align-items: center;
  background-color: #212121;
  border-radius: 8px;
  color: #fff;
  display: flex;
  font-family: Arial, sans-serif;
  margin: 9px 0;
  padding: 15px;
  position: relative;
  text-align: left;
}

.mnemo-btns {
  display: flex;
  gap: 10px;
}

.mnemo-btns button {
  margin-bottom: 0;
}

.mnemo-label {
  border-radius: 4px;
  padding: 5px 8px 3px 8px;
  font-size: 12px;
  color: #fff;
  margin-right: 5px;
  text-transform: uppercase;
}

.mnemo-labels {
  display: flex;
  position: absolute;
  top: -13px;
  left: 10px;
}

.mnemo-txt {
  font-weight: bold;
  line-height: 1.8;
  margin: 0;
}

.mnemo-txt-container {
  flex: 1;
  padding-right: 10px;
  position: relative;
  top: 3px;
}

.mnemo-translation {
  color: #aaa;
  font-size: 16px;
  margin-bottom: 2px;
  margin-top: 0;
}

.public {
  background-color: #228b22;
}

.private {
  background-color: #315fea;
}

.report-link {
  background: none;
  border: none;
  bottom: -16px;
  color: #ccc;
  cursor: pointer;
  font-size: 10px;
  font-style: italic;
  margin-bottom: 0;
  padding: 0;
  position: absolute;
  right: 0px;
  width: auto;
}

.report-link:hover {
  text-decoration: underline;
}

@media (max-width: 1000px) {
  .icon-btn {
    border-radius: 18px;
    height: 36px;
    padding: 0;
    top: -9px;
    width: 36px;
  }

  :deep(.icon-btn span) {
    top: 43px;
  }
}
</style>
