<script>
import ModalComponent from './ModalComponent.vue';

export default {
  components: {
    ModalComponent,
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitOpen() {
      this.$emit('open');
    },
    async markNotificationAsRead() {
      this.emitClose();
      try {
        await this.$api.put(`notifications/${this.notification.id}/mark_as_read`);
      } finally {
        setTimeout(() => {
          this.emitOpen();
        }, 5000);
      }
    },
  },
  name: 'NotificationModal',
  props: {
    notification: Object,
  },
};
</script>

<template>
  <ModalComponent
      :showCancelBtn="false"
      @close="emitClose"
      @submit="markNotificationAsRead"
      submitBtnText="OK"
  >
    <p v-html="notification.html" v-if="notification"></p>
  </ModalComponent>
</template>
