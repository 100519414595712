<script>
import AddEditGenerateMnemoModal from './AddEditGenerateMnemoModal.vue';
import ConfirmModal from './ConfirmModal.vue';
import IconBtn from './IconBtn.vue';
import InlineAlert from './InlineAlert.vue';
import LangDropdown from './LangDropdown.vue';
import MnemoItem from './MnemoItem.vue';
import ReportMnemoModal from './ReportMnemoModal.vue';
import _ from 'lodash';
import {getLangOptions} from '@/utils';
import {mapActions, mapState} from 'vuex';

export default {
  components: {
    AddEditGenerateMnemoModal,
    ConfirmModal,
    IconBtn,
    InlineAlert,
    LangDropdown,
    MnemoItem,
    ReportMnemoModal,
  },
  computed: {
    ...mapState(['firestoreUser', 'isLoggedIn', 'user']),
  },
  created() {
    this.debouncedSearch = _.debounce(this.searchMnemos, 500);
    this.debouncedSearch();
  },
  data() {
    return {
      addEditGenerateMnemoModalAction: null,
      addEditGenerateMnemoModalMnemo: null,
      addEditGenerateMnemoModalMnemoIsPublic: null,
      alert: null,
      isAddEditGenerateMnemoModalVisible: false,
      isConfirmDeleteMnemoModalVisible: false,
      isConfirmPublishMnemoModalVisible: false,
      isReportMnemoModalVisible: false,
      langFrom: null,
      langFromCode: null,
      langFromWordText: '',
      langOptions: getLangOptions(),
      langTo: null,
      langToCode: null,
      loading: false,
      mnemoLang: {'label': 'English', 'value': 'en'},
      mnemoLangCode: 'en',
      mnemoToDelete: null,
      mnemoToPublish: null,
      mnemos: [],
      reportMnemoModalMnemo: null,
    };
  },
  methods: {
    ...mapActions(['setBuyAiMnemosModalVisible']),
    alertReportSuccess() {
      this.alert = {
        message: 'Mnemonic reported successfully.',
        status: 'success',
      };
    },
    closeAddEditGenerateMnemoModal() {
      this.isAddEditGenerateMnemoModalVisible = false;
      this.addEditGenerateMnemoModalMnemo = null;
      this.addEditGenerateMnemoModalMnemoIsPublic = null;
    },
    closeConfirmDeleteMnemoModal() {
      this.isConfirmDeleteMnemoModalVisible = false;
    },
    closeConfirmPublishMnemoModal() {
      this.isConfirmPublishMnemoModalVisible = false;
    },
    closeReportMnemoModal() {
      this.isReportMnemoModalVisible = false;
      this.reportMnemoModalMnemo = null;
    },
    confirmDeleteMnemo(mnemo) {
      this.isConfirmDeleteMnemoModalVisible = true;
      this.mnemoToDelete = mnemo;
    },
    confirmPublishMnemo(mnemo) {
      this.isConfirmPublishMnemoModalVisible = true;
      this.mnemoToPublish = mnemo;
    },
    async deleteMnemo() {
      try {
        await this.$api.delete(`mnemos/${this.mnemoToDelete.id}`);

        this.mnemos = this.mnemos.filter(item => item.id !== this.mnemoToDelete.id);
      } catch (error) {
        this.alert = {
          message: error.response?.data?.message || 'Failed to delete mnemonic. Please try again.',
          status: 'error',
        };
      }
    },
    async publishMnemo() {
      try {
        const response = await this.$api.put(`mnemos/${this.mnemoToPublish.id}/publish`);

        this.mnemos = this.mnemos.map(item =>
            item.id === this.mnemoToPublish.id ? {...item, ...response.data} : item
        );
      } catch (error) {
        this.alert = {
          message: error.response?.data?.message || 'Failed to publish mnemonic. Please try again.',
          status: 'error',
        };
      }
    },
    async reactMnemo(mnemo, type) {
      if (!this.isLoggedIn) {
        this.alert = {
          message:
              'You need to <a href="login">log in</a> or <a href="registration">register</a> to react to a mnemonic.',
          status: 'warning',
        };
        return;
      }

      try {
        const response = await this.$api.post('mnemo_reactions', {
          mnemo_id: mnemo.id,
          type,
        });

        this.mnemos = this.mnemos.map(item =>
            item.id === mnemo.id
                ? {
                  ...item,
                  my_reaction_counts: response.data.my_reaction_counts,
                  reaction_counts: response.data.reaction_counts,
                }
                : item,
        );
      } catch (error) {
        this.alert = {
          message: error.response?.data?.message || 'Failed to add reaction. Please try again.',
          status: 'error',
        };
      }
    },
    async searchMnemos() {
      this.loading = true;
      try {
        const response = await this.$api.get('mnemos/find', {
          params: {
            lang_from_code: this.langFromCode ?? undefined,
            lang_from_word_text: this.langFromWordText === '' ? undefined : this.langFromWordText,
            lang_to_code: this.langToCode ?? undefined,
            mnemo_lang_code: this.mnemoLangCode ?? undefined,
          },
        });
        this.mnemos = response.data;
      } catch (error) {
        this.alert = {
          message: 'Something went wrong. Please try again.',
          status: 'error',
        };
      } finally {
        this.loading = false;
      }
    },
    setInputsAndSearch(langFromWordText, langFromCode, langToCode, mnemoLangCode) {
      this.langFromWordText = langFromWordText;
      this.langFromCode = langFromCode;
      this.langToCode = langToCode;
      this.mnemoLangCode = mnemoLangCode;
      this.debouncedSearch();
    },
    setMnemos(updatedMnemos) {
      this.mnemos = updatedMnemos;
    },
    showAddEditGenerateMnemoModal({action = null, isPublic = null, mnemo = null} = {}) {
      if (!this.isLoggedIn) {
          this.alert = {
            message: `You need to <a href="login">log in</a> or <a href="registration">register</a> to ${
              action
            } a mnemonic.`,
            status: 'warning',
          };
        return;
      }

      this.addEditGenerateMnemoModalAction = action;
      this.addEditGenerateMnemoModalMnemo = mnemo;
      this.addEditGenerateMnemoModalMnemoIsPublic = isPublic;
      this.isAddEditGenerateMnemoModalVisible = true;
    },
    showReportMnemoModal(mnemo) {
      this.reportMnemoModalMnemo = mnemo;
      this.isReportMnemoModalVisible = true;
    },
    async stopReviewMnemo(mnemo) {
      try {
        const response = await this.$api.put(`mnemos/${mnemo.id}/stop_review`);

        this.mnemos = this.mnemos.map(item =>
            item.id === mnemo.id ? {...item, ...response.data} : item
        );
      } catch (error) {
        this.alert = {
          message: error.response?.data?.message || 'Failed to stop the review. Please try again.',
          status: 'error',
        };
      }
    },
  },
  async mounted() {
    const stripeCheckoutSessionId = this.$route.query.stripe_checkout_session_id;

    if (stripeCheckoutSessionId) {
      try {
        await this.$api.post(`ai_mnemo_purchases/fulfill_stripe_checkout/${stripeCheckoutSessionId}`);

        const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        window.history.replaceState({path: newUrl}, '', newUrl);
      } catch (error) {
        console.error(error);
      }
    }
  },
  name: 'SearchMnemosScreen',
  watch: {
    langFromCode(newVal) {
      this.langFrom = this.langOptions.find(option => option.value === newVal) || null;
    },
    langToCode(newVal) {
      this.langTo = this.langOptions.find(option => option.value === newVal) || null;
    },
    mnemoLangCode(newVal) {
      this.mnemoLang = this.langOptions.find(option => option.value === newVal) || null;
    },
    langFromWordText() {
      this.mnemos = [];
      this.debouncedSearch();
    },
    langFrom() {
      this.mnemos = [];
      this.debouncedSearch();
    },
    langTo() {
      this.mnemos = [];
      this.debouncedSearch();
    },
    mnemoLang() {
      this.mnemos = [];
      this.debouncedSearch();
    },
  },
};
</script>

<style scoped>
#mnemo-lang-input {
  margin-bottom: 10px;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.action-buttons .icon-btn {
  height: 43px;
}

.action-buttons > * {
  flex-grow: 1;
}

.content {
  padding-bottom: 15px;
}

.form-group {
  margin-bottom: 10px;
}

.mnemo-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

:deep(input::placeholder) {
  opacity: 0;
}

button {
  width: auto;
}

@media (max-width: 1000px) {
  .action-buttons {
    margin-bottom: 21px;
  }

  .action-buttons > * {
    flex-basis: 0;
  }

  .form-group {
    margin-bottom: 0;
  }

  .inputs-container {
    flex-direction: column;
  }

  .inputs-container > * {
    width: auto;
  }

  :deep(.action-buttons .icon-btn span) {
    top: 48px;
  }

  :deep(.icon-btn span) {
    font-size: 12px;
    position: absolute;
  }
}
</style>

<template>
  <div class="content middle-wide-content">
    <h2>Search mnemonics</h2>

    <InlineAlert :message="alert.message" :status="alert.status" @close="() => alert = null" v-if="alert"/>

    <div class="inputs-container">
      <div class="form-group">
        <label for="lang-from-word-input">Word to translate</label>
        <input
            @input="debouncedSearch"
            id="lang-from-word-input"
            placeholder="Enter word to translate"
            v-model="langFromWordText"
        />
      </div>
      <div class="parallel-inputs-container">
        <div class="form-group">
          <label for="lang-from-dropdown">From</label>
          <LangDropdown
              :dataSet="langOptions"
              @select="debouncedSearch"
              class="lang-dropdown"
              id="lang-from-dropdown"
              placeholder="From"
              v-model="langFromCode"
          />
        </div>
        <div class="form-group">
          <label for="lang-to-dropdown">To</label>
          <LangDropdown
              :dataSet="langOptions"
              @select="debouncedSearch"
              class="lang-dropdown"
              id="lang-to-dropdown"
              placeholder="To"
              v-model="langToCode"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="mnemo-lang-input">Mnemonic language</label>
        <LangDropdown
            :dataSet="langOptions"
            @select="debouncedSearch"
            id="mnemo-lang-input"
            placeholder="Mnemonic language"
            v-model="mnemoLangCode"
        />
      </div>
    </div>
    <div class="action-buttons">
      <IconBtn
          @click="showAddEditGenerateMnemoModal({ action: 'add', isPublic: false })"
          bg-color="#315fea"
          icon="fa:plus"
          mobile-text="Private"
          text="Add private mnemonic"
      />
      <IconBtn
          :bg-gradient="['#315fea', '#7531ea']"
          :disabled="loading"
          :icon-size="23"
          :mobile-text="`Generate private with AI${
            isLoggedIn && user.admin
              ? ''
              : ` (${
                isLoggedIn
                  ? `${firestoreUser.aiMnemoCount} left`
                  : '10 for free after registration'
              })`
          }`"
          :text="`Generate private mnemonic with AI${
            isLoggedIn && user.admin
              ? ''
              : ` (${
                isLoggedIn
                  ? `${firestoreUser.aiMnemoCount} left`
                  : '10 for free after registration'
              })`
          }`"
          @click="showAddEditGenerateMnemoModal({ action: 'generate', isPublic: false })"
          icon="mdi:lightbulb-on"
      />
      <IconBtn
          @click="showAddEditGenerateMnemoModal({ action: 'add', isPublic: true })"
          bg-color="#228B22"
          icon="fa:plus"
          mobile-text="Public"
          text="Add public mnemonic"
      />
    </div>
    <AddEditGenerateMnemoModal
        :action="addEditGenerateMnemoModalAction"
        :langFromCode="langFromCode"
        :langFromWordText="langFromWordText"
        :langToCode="langToCode"
        :mnemo="addEditGenerateMnemoModalMnemo"
        :mnemoIsPublic="addEditGenerateMnemoModalMnemoIsPublic"
        :mnemoLangCode="mnemoLangCode"
        :mnemos="mnemos"
        @close="closeAddEditGenerateMnemoModal"
        @setInputsAndSearch="setInputsAndSearch"
        @setMnemos="setMnemos"
        v-if="addEditGenerateMnemoModalAction !== null && isAddEditGenerateMnemoModalVisible"
    />
    <ReportMnemoModal
        :langFrom="langFrom"
        :langFromWordText="langFromWordText"
        :langTo="langTo"
        :mnemo="reportMnemoModalMnemo"
        @close="closeReportMnemoModal"
        @report-success="alertReportSuccess"
        v-if="isReportMnemoModalVisible"
    />
    <ConfirmModal
        @close="closeConfirmDeleteMnemoModal"
        @confirm="deleteMnemo"
        text="Are you sure you want to delete this mnemonic?"
        v-if="isConfirmDeleteMnemoModalVisible"
    />
    <ConfirmModal
        @close="closeConfirmPublishMnemoModal"
        @confirm="publishMnemo"
        text="Are you sure you want to publish this mnemonic? Firstly, it will be reviewed. If it will be accepted, it will not be possible to change or delete it."
        v-if="isConfirmPublishMnemoModalVisible"
    />
    <div class="mnemos-container">
      <div v-if="loading">Searching...</div>
      <div v-else-if="!mnemos.length">No mnemonics found.</div>
      <h3 v-else-if="!langFromWordText">Mnemonic examples</h3>
      <div class="mnemo-list">
        <MnemoItem
            v-for="mnemo in mnemos"
            :key="mnemo.id"
            :langFromWordText="langFromWordText"
            :langFromCode="langFromCode"
            :langToCode="langToCode"
            :item="mnemo"
            :is-logged-in="isLoggedIn"
            @edit="
              (mnemoToEdit) => showAddEditGenerateMnemoModal({action: 'edit', isPublic: false, mnemo: mnemoToEdit })
            "
            @delete="confirmDeleteMnemo"
            @report="showReportMnemoModal"
            @publish="confirmPublishMnemo"
            @react="reactMnemo"
            @stop-review="stopReviewMnemo"
        />
      </div>
    </div>
  </div>
</template>
