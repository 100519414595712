<script>
import {mapActions, mapState} from 'vuex';
import FooterComponent from './FooterComponent.vue';

export default {
  components: {
    FooterComponent,
  },
  computed: {
    ...mapState(['firestoreUser', 'isLoggedIn', 'user']),
    menuVisible() {
      return window.innerWidth > 1000 || this.menuShowed;
    }
  },
  data() {
    return {
      menuShowed: false,
    };
  },
  methods: {
    ...mapActions(['logOutStore', 'setBuyAiMnemosModalVisible']),
    hideMenu() {
      this.menuShowed = false;
    },
    async logOut() {
      try {
        await this.$api.post('log_out');
      } catch (error) {
        console.error(error);
      }
      this.logOutStore();
      this.$router.push('/login');
      this.hideMenu();
    },
    showBuyAiMnemosModal() {
      this.setBuyAiMnemosModalVisible(true);
      this.hideMenu();
    },
    showMenu() {
      this.menuShowed = true;
    },
  },
  name: 'AppHeader',
  props: {
    title: {
      type: String,
      default: 'Mnemosaurus'
    }
  },
};
</script>

<style scoped>
.app-header {
  align-items: center;
  background-color: #151515;
  color: #ecf0f1;
  display: flex;
  justify-content: space-between;
  opacity: 0.9;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.app-header a {
  text-decoration: none;
}

.header-left h1 {
  margin: 0;
  font-size: 24px;
}

.menu-btn {
  background: none;
  border: none;
  color: #ecf0f1;
  cursor: pointer;
  font-size: 24px;
  margin-bottom: 0;
  padding: 0;
  width: auto;
}

.menu-close-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  padding: 0;
  position: absolute;
  top: 12px;
  right: 21px;
  width: auto;
}

.menu-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-container a {
  color: #ecf0f1;
}

.menu-container a:hover {
  text-decoration: underline;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1400;
}

@media (max-width: 1000px) {
  .ai-mnemo-count {
    font-style: italic;
    padding-top: 0 !important;
  }

  .app-header {
    opacity: 1;
  }

  .menu-container {
    background-color: #151515;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 3px 20px 20px 20px;
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    z-index: 1500;
  }

  .menu-container li {
    border-bottom: 1px solid #303030;
    padding: 20px 0;
  }

  .username-menu-item {
    border-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media (min-width: 1001px) {
  .menu-btn, .menu-close-btn {
    display: none;
  }

  .menu-container ul {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
}
</style>

<template>
  <header class="app-header">
    <div class="header-left">
      <a href="/">
        <h1>{{ title }}</h1>
      </a>
    </div>
    <button @click="showMenu" class="menu-btn">☰</button>
    <div @click="hideMenu" class="menu-overlay" v-if="menuShowed"></div>
    <nav v-if="menuVisible" class="menu-container">
      <button class="menu-close-btn" @click="hideMenu">✕</button>
      <ul>
        <li class="username-menu-item" v-if="isLoggedIn">
          <RouterLink to="edit_profile" @click="hideMenu">
            <strong>{{ user.username }}</strong>
          </RouterLink>
        </li>
        <li class="ai-mnemo-count" v-if="isLoggedIn">
          <span>AI mnemonics: {{ firestoreUser.aiMnemoCount }}</span>
        </li>
        <li>
          <RouterLink to="/" @click="hideMenu">Search mnemonics</RouterLink>
        </li>
        <li v-if="isLoggedIn">
          <RouterLink to="edit_profile" @click="hideMenu">Edit profile</RouterLink>
        </li>
        <li v-if="isLoggedIn"><a href="#" @click="showBuyAiMnemosModal">Buy AI mnemonics</a></li>
        <li v-if="isLoggedIn"><a href="#" @click="logOut">Log out</a></li>
        <li v-if="!isLoggedIn">
          <RouterLink to="login" @click="hideMenu">Login</RouterLink>
        </li>
        <li v-if="!isLoggedIn">
          <RouterLink to="registration" @click="hideMenu">Registration</RouterLink>
        </li>
      </ul>
      <FooterComponent :inNav="true"/>
    </nav>
  </header>
</template>
