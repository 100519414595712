import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyCKpFZaQUDh4W0HZnABiBCwHeyeWw1idhI',
    authDomain: 'mnemosaurus.firebaseapp.com',
    projectId: 'mnemosaurus',
    storageBucket: 'mnemosaurus.appspot.com',
    messagingSenderId: '845664137578',
    appId: '1:845664137578:web:d501ce98b83b0626c03513',
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

export { db };
