<template>
  <footer :class="{'in-nav': inNav}">
    <span>&copy; 2024 Mnemosaurus. All rights reserved.</span>
    <span class="desktop-divider">|</span>
    <span>
      <RouterLink to="privacy_policy">Privacy policy</RouterLink>
      <span>|</span>
      <RouterLink to="terms_of_service">Terms of service</RouterLink>
    </span>
    <span class="desktop-divider">|</span>
    <span class="contact-links">
      <a href="https://facebook.com/mnemosaurus.official" class="fb-link" target="_blank">
        <Icon icon="fa-facebook-official" :style="{ fontSize: 20 }"/>
      </a>
      <a href="mailto:support@mnemosaurus.com" target="_blank">
        <Icon icon="fa-envelope" :style="{ fontSize: 18 }"/><span>support@mnemosaurus.com</span>
      </a>
    </span>
  </footer>
</template>

<script>
import {Icon} from '@iconify/vue';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      fbSlug: ''
    };
  },
  name: 'FooterComponent',
  props: {
    inNav: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style scoped>
.fb-link {
  text-decoration: none;
  white-space: nowrap;
}

.fb-link:hover {
  color: #4267b2;
}

a {
  align-items: center;
  display: flex;
  gap: 10px;
}

footer {
  background-color: #151515;
  bottom: 0;
  display: flex;
  font-size: 15px;
  gap: 20px;
  justify-content: center;
  opacity: 0.9;
  padding: 20px;
  position: relative;
  text-align: center;
  width: 100%;
}

footer.in-nav {
  flex-direction: column-reverse;
  opacity: 1;
  padding: 0;
  z-index: 1501;
}

footer > * {
  position: relative;
}

footer > span {
  display: flex;
  gap: 20px;
  top: 1px;
}

@media (max-width: 1000px) {
  .contact-links {
    margin-bottom: 1px;
  }

  .desktop-divider {
    display: none;
  }

  footer {
    align-items: flex-start;
    font-size: 13px;
    gap: 15px;
    text-align: left;
  }

  footer > span {
    gap: 10px;
  }

  footer:not(.in-nav) {
    display: none;
  }
}

@media (min-width: 1000px) {
  footer.in-nav {
    display: none;
  }
}
</style>
