<template>
  <div class="content">
    <h2>Delete account</h2>

    <InlineAlert :message="alert.message" :status="alert.status" @close="() => alert = null" v-if="alert" />

    <div class="form-container">
      <div class="form-group">
        <label class="label" for="email">Email</label>
        <input
            id="email"
            type="email"
            v-model="email"
        />
      </div>

      <div class="form-group">
        <label class="label" for="password">Password</label>
        <input
            id="password"
            type="password"
            v-model="password"
        />
      </div>

      <div class="form-group">
        <label class="label" for="reason">Reason for deletion (optional)</label>
        <textarea
            id="reason"
            v-model="reason"
        />
      </div>

      <IconBtn
          :disabled="accountDeleting"
          :icon="accountDeleted ? 'fa:check' : null"
          :text="accountDeleting ? 'Deleting account...' : accountDeleted ? 'Account deleted' : 'Delete account'"
          @click="handleDeleteAccount"
      />
    </div>
  </div>
</template>

<script>
import InlineAlert from './InlineAlert.vue';
import IconBtn from './IconBtn.vue';

export default {
  components: {
    IconBtn,
    InlineAlert,
  },
  data() {
    return {
      accountDeleted: false,
      accountDeleting: false,
      alert: null,
      email: '',
      password: '',
      reason: '',
    };
  },
  methods: {
    async handleDeleteAccount() {
      if (!this.email || !this.password) {
        this.alert = {
          message: 'Please fill in all required fields.',
          status: 'error',
        };
        return;
      }

      this.accountDeleting = true;

      try {
        await this.$api.delete('users', {
          params: {
            email: this.email,
            password: this.password,
            reason: this.reason === '' ? null : this.reason,
          },
        });

        this.alert = {
          message: 'Account deleted successfully.',
          status: 'success',
        };

        this.resetForm();
      } catch (error) {
        this.alert = {
          message: error.response?.data?.message || 'An error occurred.',
          status: 'error',
        };
      } finally {
        this.accountDeleting = false;
      }
    },
    resetForm() {
      this.email = '';
      this.password = '';
      this.reason = '';
    }
  },
  name: 'DeleteAccountScreen',
};
</script>
