<script>
export default {
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
  mounted() {
    this.drawBackground();
    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      this.drawBackground();
    },
    drawBackground() {
      const canvas = this.$refs.backgroundCanvas;
      if (!canvas) {
        console.error('Canvas element not found');
        return;
      }
      const ctx = canvas.getContext('2d');
      const width = canvas.width = this.width;
      const height = canvas.height = this.height;

      const colors = ['#315fea', '#228b22', '#d97706', '#e14141', '#7531ea'];

      ctx.clearRect(0, 0, width, height);

      const area = width * height;
      const circleCount = area / 37000;

      const circles = [];
      for (let i = 0; i < circleCount; i++) {
        circles.push({
          x: Math.random() * width,
          y: Math.random() * height,
          radius: Math.random() * 30 + 10,
          color: colors[Math.floor(Math.random() * colors.length)],
        });
      }

      const lineCount = circleCount / 8;
      for (let i = 0; i < lineCount; i++) {
        const circleA = circles[Math.floor(Math.random() * circles.length)];
        const circleB = circles[Math.floor(Math.random() * circles.length)];

        const gradient = ctx.createLinearGradient(circleA.x, circleA.y, circleB.x, circleB.y);
        gradient.addColorStop(0, circleA.color);
        gradient.addColorStop(1, circleB.color);

        ctx.strokeStyle = gradient;
        ctx.lineWidth = 1;

        ctx.beginPath();
        ctx.moveTo(circleA.x, circleA.y);
        ctx.lineTo(circleB.x, circleB.y);
        ctx.stroke();
      }

      circles.forEach(circle => {
        ctx.beginPath();
        ctx.arc(circle.x, circle.y, circle.radius, 0, Math.PI * 2);
        ctx.fillStyle = circle.color;
        ctx.fill();
      });
    },
  },
};
</script>

<template>
  <canvas
      class="bg-canvas"
      ref="backgroundCanvas"
      :width="width"
      :height="height"
  ></canvas>
</template>