<template>
  <div class="content">
    <h2>Login</h2>

    <InlineAlert :message="alert.message" :status="alert.status" @close="() => alert = null" v-if="alert" />

    <form @submit.prevent="handleLogin" class="login-form">
      <div class="form-group">
        <label for="email">Email</label>
        <input v-model="email" type="email" id="email" required />
      </div>
      
      <div class="form-group">
        <label for="password">Password</label>
        <input v-model="password" type="password" id="password" required />
      </div>
      
      <button type="submit">Login</button>

      <p class="link" @click="handleForgotPassword">Forgot password?</p>
    </form>
  </div>
</template>

<script>
import InlineAlert from './InlineAlert.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    InlineAlert,
  },
  data() {
    return {
      alert: null,
      email: '',
      password: '',
    };
  },
  methods: {
    ...mapActions(['logInStore']),
    async handleLogin() {
      try {
        const response = await this.$api.post('log_in', {
          email: this.email,
          password: this.password,
        });

        if (response.data && response.data.token) {
          this.logInStore({
            token: response.data.token,
            user: response.data.user,
          });
          this.$router.push('/');
        } else {
          this.alert = {
            message: 'Invalid response from server.',
            status: 'error',
          };
        }
      } catch (error) {
        this.alert = {
          message: error.response?.data?.message || 'An error occurred.',
          status: 'error',
        };
      }
    },
    async handleForgotPassword() {
      if (!this.email) {
        this.alert = {
          message: 'Please enter your email address first.',
          status: 'warning',
        };
        return;
      }

      try {
        await this.$api.post('forgot_password', {
          email: this.email,
        });
        this.alert = {
          message: 'Password reset instructions have been sent to your email.',
          status: 'success',
        };
      } catch (error) {
        this.alert = {
          message: error.response?.data?.message || 'An error occurred.',
          status: 'error',
        };
      }
    },
  },
  mounted() {
    const message = this.$route.query.message;
    const status = this.$route.query.status;

    if (message && status) {
      this.alert = {
        message: decodeURIComponent(message.replace(/\+/g, ' ')),
        status: status,
      };

      const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.replaceState({ path: newUrl }, '', newUrl);
    }
  },
  name: 'LoginScreen',
};
</script>

<style scoped>
.login-form {
  display: flex;
  flex-direction: column;
}

button {
  margin-top: 5px;
}

.link {
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
}

.link:hover {
  text-decoration: underline;
}
</style>
