<template>
  <div class="content">
    <h2>Reset password</h2>

    <InlineAlert :message="alert.message" :status="alert.status" @close="() => alert = null" v-if="alert" />

    <div class="form-container">
      <div class="form-group">
        <label class="label" for="new-password">New password</label>
        <input
            id="new-password"
            type="password"
            v-model="newPassword"
        />
      </div>

      <div class="form-group">
        <label class="label" for="new-password-confirmation">Confirm new password</label>
        <input
            id="new-password-confirmation"
            type="password"
            v-model="newPasswordConfirmation"
        />
      </div>

      <IconBtn
          :disabled="savingNewPassword"
          :icon="newPasswordSaved ? 'fa:check' : null"
          :text="savingNewPassword ? 'Saving new password...' : newPasswordSaved ? 'New password saved' : 'Save new password'"
          @click="handleSaveNewPassword"
      />
    </div>
  </div>
</template>

<script>
import InlineAlert from './InlineAlert.vue';
import IconBtn from "@/components/IconBtn.vue";

export default {
  components: {
    IconBtn,
    InlineAlert,
  },
  data() {
    return {
      alert: null,
      newPassword: '',
      newPasswordConfirmation: '',
      newPasswordSaved: false,
      savingNewPassword: false,
    };
  },
  methods: {
    async handleSaveNewPassword() {
      if (!this.newPassword || !this.newPasswordConfirmation) {
        this.alert = {
          message: 'Please fill in all fields.',
          status: 'error',
        };
        return;
      }

      if (this.newPassword !== this.newPasswordConfirmation) {
        this.alert = {
          message: 'Passwords do not match.',
          status: 'error',
        };
        return;
      }

      this.savingNewPassword = true;

      try {
        await this.$api.post('reset_password', {
          email: this.$route.query.email,
          password: this.newPassword,
          password_confirmation: this.newPasswordConfirmation,
          token: this.$route.query.token,
        });

        this.alert = {
          message: 'Password successfully reset! You can now <a href="login">log in</a> with your new password.',
          status: 'success',
        };

        this.resetForm();
      } catch (error) {
        this.alert = {
          message: error.response?.data?.message || 'An error occurred.',
          status: 'error',
        };
      } finally {
        this.savingNewPassword = false;
      }
    },
    resetForm() {
      this.newPassword = '';
      this.newPasswordConfirmation = '';
    },
  },
  name: 'ResetPasswordScreen',
};
</script>
