<template>
  <div class="content wide-content">
    <h2>Terms of service</h2>

    <p><strong>Effective date:</strong> October 9, 2024</p>
    <br>

    <h3>1. Acceptance of terms</h3>
    <p>By accessing or using Mnemosaurus ("we," "our," or "us"), you agree to comply with and be bound by the following
      terms of service.</p>
    <br>

    <h3>2. User responsibilities</h3>
    <p>Users agree not to use Mnemosaurus for any unlawful purposes or in a manner that infringes the rights of others.
      Users are responsible for keeping their account information confidential and for all activities that occur under
      their account.</p>

    <p><strong>Multiple accounts:</strong> Each user may only create and maintain one account. Creating multiple
      accounts by the same individual is strictly prohibited. If we detect multiple accounts associated with the same
      individual, we reserve the right to suspend or terminate all associated accounts.</p>
    <br>

    <h3>3. Prohibited Activities</h3>
    <p>Users are prohibited from the following activities:</p>
    <ul>
      <li>Using any automated system, including but not limited to "robots," "spiders," or "offline readers," to access
        the service in a manner that sends more request messages to the servers than a human can reasonably produce in
        the same period by using a conventional online web browser.
      </li>
      <li>Scraping, data mining, or similar data gathering and extraction tools without our express written
        permission.
      </li>
      <li>Attempting to interfere with, compromise the system integrity or security, or decipher any transmissions to or
        from the servers running the service.
      </li>
      <li>Any other activity that may disrupt the normal functioning of the service.</li>
    </ul>
    <br>

    <h3>4. Intellectual property</h3>
    <p>All content, features, and functionality on Mnemosaurus, including mnemonics, images, logos, and software, are
      the exclusive property of Mnemosaurus and are protected by intellectual property laws. Users may not reproduce,
      distribute, or modify any content without permission.</p>
    <br>

    <h3>5. User-generated content</h3>
    <p>Users may contribute mnemonics or other content to Mnemosaurus. By contributing content, you grant Mnemosaurus a
      non-exclusive, worldwide license to use, display, and distribute your content. You retain all rights to your
      original content.</p>

    <p><strong>Public mnemonics:</strong> You agree that Mnemosaurus may use, modify, or share these mnemonics for the
      purpose of improving the platform, training AI models, and other related uses. Before publishing, we review each
      mnemonic to ensure it is appropriate for children and may modify grammar or style to maintain data quality and
      consistency within our app.</p>

    <p><strong>Private mnemonics:</strong> Mnemosaurus may use private mnemonics for AI model training and platform
      improvements.</p>
    <br>

    <h3>6. AI-generated content</h3>
    <p>Mnemosaurus utilizes artificial intelligence to generate content and suggestions. While we strive to ensure that
      all AI-generated content is appropriate and accurate, we do not guarantee its suitability or correctness. Users
      acknowledge that AI-generated content may not always be appropriate or free from errors, and we are not liable for
      any issues arising from its use.</p>
    <br>

    <h3>7. Limitation of liability</h3>
    <p>Mnemosaurus is provided "as is" without any warranties of any kind, either express or implied. We do not
      guarantee the accuracy, reliability, or availability of the app. We are not liable for any damages arising from
      the use or inability to use Mnemosaurus.</p>
    <br>

    <h3>8. Account suspension and termination</h3>
    <p>We reserve the right to suspend or terminate user accounts at our discretion, especially in cases where the terms
      are violated or illegal activities are detected.</p>
    <br>

    <h3>9. Governing law</h3>
    <p>These terms shall be governed by and construed in accordance with the laws of Czechia. Any legal actions arising
      from these terms will be brought in the courts located in Prague, Czechia.</p>
    <br>

    <h3>10. Changes to the terms</h3>
    <p>We reserve the right to update or change these terms at any time. It is the responsibility of users to regularly review these terms for any changes. Continued use of our app following the posting of any changes constitutes acceptance of those changes.</p>
    <br>

    <h3>11. Contact information</h3>
    <p>If you have any questions about these terms, please contact us at <a href="mailto:support@mnemosaurus.com">support@mnemosaurus.com</a>.
    </p>
  </div>
</template>
<script setup lang="ts">
</script>