<template>
  <vue-select
      :options="dataSet"
      v-model="selected"
  />
</template>

<script>
import vueSelect from 'vue3-select-component';

export default {
  name: 'LangDropdown',
  components: { vueSelect },
  props: {
    dataSet: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selected: this.value
    };
  },
  watch: {
    value(val) {
      this.selected = val;
    }
  },
  methods: {
    selectItem(item) {
      this.$emit('select', item);
    }
  }
};
</script>

<style>
svg {
  color: #ccc !important;
}

.no-results {
  background-color: #232323 !important;
  color: white !important;
}

.single-value {
  color: #fff !important;
  font-size: 14px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>
