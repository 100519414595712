import DeleteAccountScreen from '../components/DeleteAccountScreen.vue';
import EditProfileScreen from '../components/EditProfileScreen.vue';
import LoginScreen from '../components/LoginScreen.vue';
import PageNotFound from '../components/PageNotFound.vue';
import PrivacyPolicyScreen from '../components/PrivacyPolicyScreen.vue';
import RegistrationScreen from '../components/RegistrationScreen.vue';
import ResetPasswordScreen from '../components/ResetPasswordScreen.vue';
import SearchMnemosScreen from '../components/SearchMnemosScreen.vue';
import TermsOfServiceScreen from '../components/TermsOfServiceScreen.vue';
import {createRouter, createWebHistory} from 'vue-router';
import store from '../store';

const routes = [
    {
        component: SearchMnemosScreen,
        path: '/',
    },
    {
        component: DeleteAccountScreen,
        path: '/delete_account',
    },
    {
        component: EditProfileScreen,
        path: '/edit_profile',
    },
    {
        component: LoginScreen,
        path: '/login',
    },
    {
        component: PrivacyPolicyScreen,
        meta: {noindex: true},
        path: '/privacy_policy',
    },
    {
        component: RegistrationScreen,
        path: '/registration',
    },
    {
        component: ResetPasswordScreen,
        path: '/reset_password',
    },
    {
        component: TermsOfServiceScreen,
        meta: {noindex: true},
        path: '/terms_of_service',
    },
    {
        component: PageNotFound,
        meta: {noindex: true},
        path: '/:pathMatch(.*)*',
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return {top: 0};
    },
});

router.beforeEach((to) => {
    const isLoggedIn = store.state.isLoggedIn;

    if (
        (isLoggedIn && (to.path === '/login' || to.path === '/registration'))
        || (!isLoggedIn && to.path === '/edit_profile')
    ) {
        return '/';
    } else {
        return true;
    }
});

export default router;
