<script>
import { mapState } from 'vuex';
import IconBtn from './IconBtn.vue';
import InlineAlert from './InlineAlert.vue';

export default {
  name: 'EditProfileScreen',
  computed: {
    ...mapState(['user']),
  },
  components: {
    InlineAlert,
    IconBtn,
  },
  data() {
    return {
      alert: null,
      confirmNewPassword: '',
      currentPassword: '',
      loading: true,
      newPassword: '',
      passwordUpdated: false,
      passwordUpdating: false,
      username: '',
      usernameUpdated: false,
      usernameUpdating: false,
    };
  },
  methods: {
    async fetchProfile() {
      try {
        const response = await this.$api.get(`users/${this.user.id}`);
        this.username = response.data.username;
        this.$store.commit('SET_USER', response.data);
      } catch (error) {
        this.alert = { message: 'Failed to load profile information.', status: 'error' };
      } finally {
        this.loading = false;
      }
    },
    async handleChangePassword() {
      if (!this.currentPassword || !this.newPassword || !this.confirmNewPassword) {
        this.alert = { message: 'Please fill in all fields.', status: 'error' };
        return;
      }

      if (this.newPassword !== this.confirmNewPassword) {
        this.alert = { message: 'Passwords do not match.', status: 'error' };
        return;
      }

      this.passwordUpdating = true;
      try {
        await this.$api.put(`users/${this.user.id}/change_password`, {
          current_password: this.currentPassword,
          new_password: this.newPassword,
          new_password_confirmation: this.confirmNewPassword,
        });

        this.currentPassword = '';
        this.newPassword = '';
        this.confirmNewPassword = '';
        this.passwordUpdated = true;
      } catch (error) {
        this.alert = {
          message: error.response?.data?.message || 'Failed to change password. Please try again.',
          status: 'error',
        }
      } finally {
        this.passwordUpdating = false;
      }
    },
    async handleUpdateProfile() {
      if (!this.username) {
        this.alert = { message: 'Please fill in the username.', status: 'error' };
        return;
      }

      this.usernameUpdating = true;
      try {
        const response = await this.$api.put(`users/${this.user.id}`, {
          username: this.username,
        });

        this.$store.commit('SET_USER', response.data.user);
        this.usernameUpdated = true;
      } catch (error) {
        this.alert = { message: 'Failed to update profile. Please try again.', status: 'error' };
      } finally {
        this.usernameUpdating = false;
      }
    },
  },
  async created() {
    await this.fetchProfile();
  },
};
</script>

<style scoped>
.loading-container {
  font-size: 18px;
}

.divider {
  margin: 16px 0;
  border: none;
  height: 1px;
  background-color: #ccc;
}
</style>

<template>
  <div class="content">
    <h2>Edit profile</h2>

    <InlineAlert :message="alert.message" :status="alert.status" @close="() => alert = null" v-if="alert"/>

    <div v-if="loading" class="loading-container">
      <span>Loading...</span>
    </div>
    <div v-else>
      <div class="form-group">
        <label for="username">Username</label>
        <input
            @input="() => usernameUpdated = false"
            id="username"
            type="text"
            v-model="username"
        />
      </div>
      <IconBtn
          :disabled="usernameUpdating"
          :icon="usernameUpdated ? 'fa:check' : null"
          :text="usernameUpdating ? 'Updating username...' : usernameUpdated ? 'Username updated' : 'Update username'"
          @click="handleUpdateProfile"
      />

      <hr class="divider" />

      <div class="form-group">
        <label for="currentPassword">Current password</label>
        <input
            @input="() => passwordUpdated = false"
            id="currentPassword"
            type="password"
            v-model="currentPassword"
        />
      </div>

      <div class="form-group">
        <label for="newPassword">New password</label>
        <input
            @input="() => passwordUpdated = false"
            id="newPassword"
            type="password"
            v-model="newPassword"
        />
      </div>

      <div class="form-group">
        <label for="confirmNewPassword">Confirm new password</label>
        <input
            @input="() => passwordUpdated = false"
            id="confirmNewPassword"
            type="password"
            v-model="confirmNewPassword"
        />
      </div>

      <IconBtn
          :disabled="passwordUpdating"
          :icon="passwordUpdated ? 'fa:check' : null"
          :text="passwordUpdating ? 'Updating password...' : passwordUpdated ? 'Password updated' : 'Update password'"
          @click="handleChangePassword"
      />
    </div>
  </div>
</template>
