<template>
  <div class="inline-alert" :style="alertClasses">
    <span v-html="message"></span>
    <button @click="close">&times;</button>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      required: true,
      type: String,
    },
    status: {
      default: 'no-state',
      type: String,
    },
  },
  computed: {
    alertClasses() {
      const statusStyles = {
        success: {
          backgroundColor: '#228b22',
        },
        error: {
          backgroundColor: '#e14141',
        },
        'no-state': {
          backgroundColor: '#315fea',
        },
        warning: {
          backgroundColor: '#d97706',
        },
      };
      return statusStyles[this.status];
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 0;
  padding: 0;
  position: relative;
  top: 1px;
  width: auto;
}

span {
  flex-grow: 1;
}
</style>
