<template>
  <button
      :class="['icon-btn', marked ? 'marked' : '']"
      :disabled="disabled"
      :style="{ '--bg-color': bgColor, ...bgGradient ? { backgroundImage: `linear-gradient(to right, ${bgGradient[0]}, ${bgGradient[1]})` } : {} }"
  >
    <Icon :icon="icon" :style="{ fontSize: iconSize }" v-if="icon" :class="icon.replace(':', '-')" />
    <span class="icon-btn-text" v-if="text">{{ text }}</span>
    <span class="icon-btn-mobile-text">{{ mobileText ?? text }}</span>
  </button>
</template>

<script>
import {Icon} from '@iconify/vue';

export default {
  name: 'IconButton',
  components: {
    Icon,
  },
  props: {
    bgColor: {
      type: String,
      default: '#315fea',
    },
    bgGradient: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    iconSize: {
      type: Number,
      default: 16,
    },
    marked: {
      type: Boolean,
      default: false,
    },
    mobileText: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
svg {
  margin-right: 8px;
}

.fa-check, .mdi-lightbulb-on {
  position: relative;
  top: -1px;
}

.icon-btn {
  align-items: center;
  background-color: var(--bg-color);
  display: flex;
  justify-content: center;
  position: relative;
}

.icon-btn.marked {
  background-color: white;
  color: var(--bg-color);
}

.icon-btn svg {
  color: white;
}

.icon-btn-mobile-text {
  display: none;
}

.icon-btn.marked svg {
  color: var(--bg-color);
}

@media (max-width: 1000px) {
  svg {
    margin-right: 0;
  }

  .icon-btn-text {
    display: none;
  }

  .icon-btn-mobile-text {
    display: block;
  }
}
</style>
