<script>
import ModalComponent from './ModalComponent.vue';

export default {
  components: {
    ModalComponent,
  },
  data() {
    return {
      alert: null,
      reason: '',
      submitting: false,
    };
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
    emitReportSuccess() {
      this.$emit('report-success');
    },
    async submitReport() {
      this.submitting = true

      try {
        await this.$api.post('mnemo_reports', {
          mnemo_id: this.mnemo.id,
          reason: this.reason,
        });
        this.reason = '';

        this.emitReportSuccess();
        this.emitClose();
      } catch (error) {
        this.alert = {
          message: error.response.data.message,
          status: 'error',
        };
      } finally {
        this.submitting = false;
      }
    },
  },
  name: "ReportMnemoModal",
  props: {
    langFrom: Object,
    langFromWordText: String,
    langTo: Object,
    mnemo: Object,
  },
};
</script>

<template>
  <ModalComponent
      :alert="alert"
      :submitting="submitting"
      :title="'Report Mnemonic'"
      @close="emitClose"
      @closeAlert="() => this.alert = null"
      @submit="submitReport"
  >
<!--    <div class="form-group">-->
<!--      <label>Word in {{ langFrom.label }}</label>-->
<!--      <em>{{ langFromWordText }}</em>-->
<!--    </div>-->

<!--    <div class="form-group">-->
<!--      <label>Word in {{ langTo.label }}</label>-->
<!--      <em>{{ mnemo.lang_to_word_text }}</em>-->
<!--    </div>-->

    <div class="form-group">
      <label>Mnemonic text</label>
      <em>{{ mnemo.text }}</em>
    </div>

    <div class="form-group">
      <label for="reason">Reason for reporting</label>
      <textarea
          id="reason"
          placeholder="If it's obvious, let empty"
          v-model="reason"
      />
    </div>
  </ModalComponent>
</template>
