<template>
  <div class="content wide-content">
    <h2>Privacy policy</h2>

    <p><strong>Effective date:</strong> October 9, 2024</p>
    <br>

    <p>Mnemosaurus ("we", "our", or "us") is committed to protecting your privacy. This privacy policy explains how we collect, use, and share information about you when you use our app.</p>
    <br>

    <h3>1. Information we collect</h3>
    <p>We collect the following types of information:</p>
    <ul>
      <li><strong>Personal information:</strong> If you choose to create an account or share mnemonics, we may collect personal information such as your name, email address, and any content you contribute.</li>
      <li><strong>Device information:</strong> We may collect device-related information, such as IP addresses, device type, operating system, and app usage statistics, to improve the app experience and diagnose technical issues.</li>
      <li><strong>Payment information:</strong> If you use our paid services, payment information is processed by third-party providers like Stripe, which may collect necessary data for fraud prevention and transaction security.</li>
    </ul>
    <br>

    <h3>2. How we use your information</h3>
    <p>We use the collected information for the following purposes:</p>
    <ul>
      <li>To provide and improve our app functionality.</li>
      <li>To personalize your experience, including recommending mnemonics based on your activity.</li>
      <li>To communicate with you regarding updates, promotions, or support.</li>
      <li>To ensure the security of your data and prevent fraud.</li>
      <li>AI model training: This data will be anonymized and used solely for the purpose of improving Mnemosaurus's capabilities.</li>
    </ul>
    <br>

    <h3>3. Data sharing and disclosure</h3>
    <p>We do not sell or share your personal information with third parties for marketing purposes. However, we may share your data under the following circumstances:</p>
    <ul>
      <li><strong>With service providers:</strong> We may share your information with trusted service providers that help us operate the app, such as cloud hosting or analytics services.</li>
      <li><strong>Legal requirements:</strong> We may disclose your information if required by law or in response to legal requests, such as subpoenas or court orders.</li>
    </ul>
    <br>

    <h3>4. Data security</h3>
    <p>We use industry-standard security measures to protect your personal information from unauthorized access, loss, or misuse. We continuously work to enhance our security practices to safeguard your data.</p>
    <br>

    <h3>5. User rights and control</h3>
    <p>You have the right to:</p>
    <ul>
      <li>Access, update, or delete your personal information.</li>
      <li>Opt-out of promotional communications.</li>
      <li>Withdraw your consent to data processing at any time.</li>
    </ul>
    <p>To exercise any of these rights, please contact us at <a href="mailto:support@mnemosaurus.com">support@mnemosaurus.com</a>.</p>
    <br>

    <h3>6. Request to delete your data</h3>
    <p>If you would like to request that we permanently delete your account and all associated personal data (including username, email, and any other personal information), you can do so by filling the form at <a href="https://mnemosaurus.com/delete_account.php">https://mnemosaurus.com/delete_account</a>. We will process your request within 30 days.</p>

    <p>Note: Once your data is deleted, it cannot be recovered. We may retain anonymized or aggregated data for statistical purposes, which will no longer be linked to your personal identity.</p>
    <br>

    <h3>7. Retention of anonymized data</h3>
    <p>When a user requests the deletion of their account and personal data, we will permanently delete their identifiable information (such as username and email) from our systems.</p>

    <p>However, any content contributed to the platform, such as mnemonics, may be retained in an <strong>anonymized</strong> format that cannot be linked back to the user. This anonymized data will be used solely for improving the platform and providing insights, and it will no longer be associated with any personal information.</p>
    <br>

    <h3>8. Changes to the privacy policy</h3>
    <p>We may update this privacy policy from time to time. It is the responsibility of users to regularly review this policy for any changes. Continued use of our app following the posting of any changes constitutes acceptance of those changes.</p>
    <br>

    <h3>9. Contact us</h3>
    <p>If you have any questions about this privacy policy, please contact us at:</p>
    <p>Email: <a href="mailto:support@mnemosaurus.com">support@mnemosaurus.com</a></p>
  </div>
</template>
